angular.module('app').controller('searchBarController', searchBarController);

function searchBarController($scope, ShoppingCartService, ArticleService) {

    $scope.filter = ArticleService.filter;

    this.refresh = function () {
        ArticleService.refresh();
    }

    this.search = function () {
        ArticleService.search();
    }
}