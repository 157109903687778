angular.module('app').controller('UserController', UserController);

function UserController($rootScope, $scope, $http, $window) {

    $scope.user = {
        discount: 0
    };

    this.submit = function () {
        if ($scope.user.login_name === '') {
            $scope.user.login_name = null;
        }
        if ($scope.user.shipping_zone_id === '') {
            $scope.user.shipping_zone_id = null;
        }
        if ($scope.user.shipping_tour_id === '') {
            $scope.user.shipping_tour_id = null;
        }

        $http.post(userPostURL, $scope.user).then(
            function () {
                $window.location.href = "/admin/felhasznalok";
            },
            function (data) {
                if (typeof data === 'string') {
                    alert(data);
                }
                else if (data.data) {
                    let messages = '';
                    for (let key in data.data) {
                        let message = data.data[key][0];
                        messages += message+'<br/>';
                    }
                    $('#messages').html(messages);
                    $('#messages').show();
                }
            });
    };


    $scope.deleteUser = function (id) {
        if (confirm('Biztosan törlöd ezt a felhasználót')) {
            var url = userDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade', 250, function () {
                        $('#row-' + id).remove();
                    });
                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    };
}
