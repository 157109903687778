angular.module('app').controller('shoppingCartController', shoppingCartController);

function shoppingCartController($scope, ShoppingCartService, ArticleService) {

    $scope.shoppingCart = ShoppingCartService.shoppingCart;
    $scope.shoppingCartOrdered = ShoppingCartService.shoppingCartOrdered;
    $scope.shoppingCartDeliveries = ShoppingCartService.shoppingCartDeliveries;
    $scope.user = ShoppingCartService.user;
    $scope.filter = ArticleService.filter;
    $scope.loading = {};

    this.remove = function (shoppingCartItem) {
        $scope.loading[shoppingCartItem.id] = true;
        ShoppingCartService.removeFromCart(shoppingCartItem).then(function () {
            delete $scope.loading[shoppingCartItem.id];
        });
    }

    this.order = function () {
        $scope.loading.order = true;
        ShoppingCartService.order().then(function () {
            delete $scope.loading.order;
            $('#shoppingCartOrdered')
                .modal('show');
        });
    }

    this.updateDelivery = function () {
        var isOrderModalValid = $scope.shoppingCartDeliveries.reduce((isValid, shoppingCartDelivery) => {
            return isValid && shoppingCartDelivery.paymentMethod !== 'undefined';
        }, true);

        window.isOrderModalValid = isOrderModalValid;


        if (isOrderModalValid) {
            ShoppingCartService.updateDelivery().then(function () {
            });
        }
    }

    this.isLoading = function (shoppingCartItem) {
        return ($scope.loading && $scope.loading[shoppingCartItem.id]);
    }

    this.getDeliveries = function () {
        var deliveries = [];
        _.forEach($scope.shoppingCart.shoppingCartItems, function (shoppingCartItem) {
            var delivery = _.find(deliveries, function (_delivery) {
                    return (_delivery.id == shoppingCartItem.delivery.id);
                });
            if (!delivery) {
                shoppingCartItem.delivery.jsDate = new Date(shoppingCartItem.delivery.date);
                shoppingCartItem.delivery.jsClosingDate = new Date(shoppingCartItem.delivery.closing_date);
                deliveries.push(shoppingCartItem.delivery);
            }
        })
        return deliveries;
    }

    this.getOrderedDeliveries = function () {
        var deliveries = [];
        _.forEach($scope.shoppingCartOrdered.shoppingCartItems, function (shoppingCartItem) {
            var delivery = _.find(deliveries, function (_delivery) {
                return (_delivery.id == shoppingCartItem.delivery.id);
            });
            if (!delivery) {
                shoppingCartItem.delivery.jsDate = new Date(shoppingCartItem.delivery.date);
                shoppingCartItem.delivery.jsClosingDate = new Date(shoppingCartItem.delivery.closing_date);
                deliveries.push(shoppingCartItem.delivery);
            }
        })
        return deliveries;
    }


    this.getShoppingCartItems = function (delivery) {
        var shoppingCartItems = [];
        _.forEach($scope.shoppingCart.shoppingCartItems, function (shoppingCartItem) {
            if (delivery.id == shoppingCartItem.delivery.id) {
                shoppingCartItems.push(shoppingCartItem);
            }
        });

        return shoppingCartItems;
    }

    this.getOrderedShoppingCartItems = function (delivery) {
        var shoppingCartItems = [];
        _.forEach($scope.shoppingCartOrdered.shoppingCartItems, function (shoppingCartItem) {
            if (delivery.id == shoppingCartItem.delivery.id) {
                shoppingCartItems.push(shoppingCartItem);
            }
        });

        return shoppingCartItems;
    }

    this.getFormattedDeliveryAddress = function (user) {
        if (user.isDifferentShippingAddress) {
            return `${user.shippingZipcode} ${user.shippingCity}, ${user.shippingPublicDomain} ${user.shippingPublicDomainType} ${user.shippingAddressNumber}`;
        }

        return `${user.companyZipcode} ${user.companyCity}, ${user.companyPublicDomain} ${user.companyPublicDomainType} ${user.companyAddressNumber}`;
    }

    this.getShippingCost = function (delivery) {
        if (delivery) {
            var shoppingCartDelivery = $scope.shoppingCartDeliveries.find(shoppingCartDelivery => shoppingCartDelivery.deliveryId === delivery.id);
            return this.getShippingCostByShoppingCartDelivery(shoppingCartDelivery);
        }

        var shippingCost = 0;
        $scope.shoppingCartDeliveries.forEach(shoppingCartDelivery => shippingCost = shippingCost + this.getShippingCostByShoppingCartDelivery(shoppingCartDelivery));

        return shippingCost;
    }

    this.getShippingCostByShoppingCartDelivery = function (shoppingCartDelivery, isShipping = false) {
        return (shoppingCartDelivery.shippingMethod === 'shipping' || isShipping) && $scope.user.shippingZone ? $scope.user.shippingZone.cost : 0;
    }

    this.getShippingDate = function (delivery) {
        var shoppingCartDelivery = $scope.shoppingCartDeliveries.find(shoppingCartDelivery => shoppingCartDelivery.deliveryId === delivery.id);
        return this.getShippingDateByShoppingCartDelivery(shoppingCartDelivery);
    }

    this.getShippingDateByShoppingCartDelivery = function (shoppingCartDelivery, isShipping = false) {
        var shippingTourDates = (shoppingCartDelivery.shippingMethod === 'shipping' || isShipping) && $scope.user.shippingTour && $scope.user.shippingTour.shippingTourDates ? $scope.user.shippingTour.shippingTourDates : null;
        if (!shippingTourDates) {
            return null;
        }
        var deliveryDay = shoppingCartDelivery.delivery.jsDate.getDay() || 7;

        var shippingTourDate = shippingTourDates.find(shippingTourDate => shippingTourDate.deliveryDayOfWeek === deliveryDay);
        if (!shippingTourDate) {
            return null;
        }

        return new Date().setDate(shoppingCartDelivery.delivery.jsDate.getDate() + shippingTourDate.shippingOffsetDays);
    }
}