angular.module('app').controller('StockController', StockController);

function StockController($rootScope, $scope, $http) {

    $scope.recalcStock = function() {
        $scope.stock  = $scope.amount_per_unit * $scope.units;
        $scope.free = $scope.stock - $scope.reserved - $scope.sold;
    }

    $scope.deleteStock = function (id) {
        if (confirm('Biztosan törlöd ezt a tételt?')) {
            var url = stockDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade',250,function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    }

}
