angular.module('app').controller('filterController', filterController);

function filterController($scope, HttpService, ArticleService, ErrorHandlingService, ShoppingCartService) {

    $scope.filter = ArticleService.filter;
    $scope.loading = ArticleService.loading;

    this.setProductGroup = function (productGroup) {
        ArticleService.setProductGroup(productGroup);
    };

    this.setDelivery = function (delivery) {
        ArticleService.setDelivery(delivery);
    };

    this.search = function () {
        ArticleService.search();
    }
}