angular.module('app').service('TimeoutService', TimeoutService);

function TimeoutService($timeout) {

    let timeout = null;

    let hasCartElements = false;

    let ctrl = this;

    startTimer = function () {
        cancelTimer();
        if (hasCartElements) {
            timeout = $timeout(timedOut, 30 * 60 * 1000);
        }
    };

    let cancelTimer = function () {
        if (timeout) {
            $timeout.cancel(timeout);
        }
        timeout = null;
    };

    this.shoppingCartChanged = function(hasElement) {
        hideModal();
        hasCartElements = hasElement;
        startTimer();
    };

    let timedOut = function() {
        cancelTimer();
        $('#timeoutModal').modal('show');
    };

    this.continue = function() {
        hideModal();
        startTimer();
    }

    hideModal = function() {
        $('#timeoutModal').modal('hide');
    }
}