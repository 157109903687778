angular.module('app').controller('PurchaseController', PurchaseController);

function PurchaseController($window, $scope, $http) {
    $scope.purchase = {};
    $scope.purchases = null;

    $scope.submit = function () {
        if (confirm(`Biztosan rögzíted ezt a vásárlási tételt?\n\n\n${$scope.purchase.amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} Ft\n\n`)) {
            $http.post(purchasePostUrl, $scope.purchase).then(
                function (data) {
                    $window.location.href = "/";
                },
                function (data) {
                    if (typeof data === 'string' || data.data && data.data.substr(0, 5) == '<!DOC') {
                        $('#messages').html(data.data);
                        $('#messages').show();
                    } else if (data.data) {
                        let messages = '';
                        if (data.data instanceof Array) {
                            for (let key in data.data) {
                                let message = data.data[key][0];
                                messages += message + '<br/>';
                            }
                            $('#messages').html(messages);
                        } else {
                            $('#messages').html(data.data);
                        }
                        $('#messages').show();
                    }
                });
        }
    };

    $scope.deletePurchase = function (id) {
        if (confirm('Biztosan törlöd ezt a vásárlást?')) {
            var url = purchaseDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade', 250, function () {
                        $('#row-' + id).remove();
                    });
                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    };

}
