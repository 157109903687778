
angular.module('app').service('ArticleService', ArticleService);

function ArticleService(HttpService, ErrorHandlingService) {

    var articleService = this;

    articleService.articles = [];
    articleService.filter = {};
    articleService.loading = {};

    articleService.setProductGroup = function (productGroup) {
        articleService.filter.productGroup = productGroup;

        articleService.loadArticles(articleService.filter.delivery, articleService.filter.productGroup);
    };

    this.setDelivery = function (delivery) {
        articleService.filter.delivery = delivery;

        articleService.loadProductGroups(delivery);
    };

    articleService.loadDeliveries = function () {
        HttpService.get('/api/deliveries').then(
            function (response) {
                articleService.filter.deliveries = [];
                angular.forEach(response.data, function (delivery) {
                    delivery.jsDate = new Date(delivery.date);
                    delivery.jsClosingDate = new Date(delivery.closing_date);
                    articleService.filter.deliveries.push(delivery);
                });

                if (articleService.filter.deliveries.length > 0) {
                    articleService.setDelivery(articleService.filter.deliveries[0]);
                } else {
                    articleService.setDelivery(null);
                }
            }, function (error) {
                ErrorHandlingService.handleException(error.data);
            });

    }

    articleService.loadProductGroups = function (delivery) {
        if (delivery) {
            articleService.loading.loading = true;

            HttpService.get('/api/productGroups', {'delivery_id': delivery.id})
                .then(function (response) {
                    articleService.filter.productGroups = [];
                    angular.forEach(response.data, function (productGroup) {
                        articleService.filter.productGroups.push(productGroup);
                    });

                    if (articleService.filter.productGroups.length > 0) {
                        articleService.setProductGroup(articleService.filter.productGroups[0]);
                    } else {
                        articleService.setProductGroup(null);
                    }
                    delete articleService.loading.loading;
                }, function (error) {
                    delete articleService.loading.loading;
                    ErrorHandlingService.handleException(error.data);
                });
        } else {
            articleService.filter.productGroups = [];
            articleService.setProductGroup(null);
        }
    }

    articleService.loadArticles = function (delivery, productGroup) {
        articleService.filter.searchPhrase = "";
        if (delivery && productGroup) {
            HttpService.get('/api/articlesForMarketplace', {'delivery_id': delivery.id, 'product_group_id': productGroup.id}).then(
                function (response) {
                    articleService.articles.splice(0, articleService.articles.length);

                    angular.forEach(response.data, function(article) {
                        article.orderUnits = 1;
                        articleService.articles.push(article);
                    });

                }, function (error) {
                    ErrorHandlingService.handleException(error.data);
                });
        } else {
            articleService.articles.splice(0, articleService.articles.length);
        }
    }

    articleService.getArticles = function (deliveryId) {
        HttpService.get('/api/articles', {'delivery_id': deliveryId}).then(
            function (response) {
                articleService.articles.splice(0, articleService.articles.length);

                angular.forEach(response.data, function(article) {
                    article.orderUnits = 1;
                    articleService.articles.push(article);
                });

            }, function (error) {
                ErrorHandlingService.handleException(error.data);
            });
    }

    articleService.refresh = function () {
        articleService.loadArticles(articleService.filter.productGroup, articleService.filter.delivery);
    }

    articleService.getArticle = function (id) {
        var foundArticle = null;
        angular.forEach(articleService.articles, function (article) {
            if (article.id === id) {
                foundArticle = article;
                return;
            }
        });

        return foundArticle;
    }

    articleService.search = function () {
        var foundFirst = false;
        angular.forEach(articleService.articles, function (article) {
            article.found = false;
            article.foundFirst = false;
            if (articleService.filter.searchPhrase && article.product.name.toLowerCase().indexOf(articleService.filter.searchPhrase.toLowerCase()) > -1) {
                article.found = true;
                if (!foundFirst) {
                    foundFirst = true;
                    article.foundFirst = true;
                }
            }
        });
    }
}