angular.module('app').controller('TimeoutController', TimeoutController);

function TimeoutController($scope, ShoppingCartService, TimeoutService) {

    $scope.handleClick = function(event) {
        console.log('click');
        TimeoutService.shoppingCartChanged(ShoppingCartService.hasElement());
    };

    $scope.ordering = false;

    $scope.closeOrder = function () {
        TimeoutService.continue();
        $('#shoppingCartDeliveryAndPaymentInfo')
            .modal('show');
    };

    $scope.continue = function () {
        TimeoutService.continue();
    };
}