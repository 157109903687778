angular.module('app').controller('ProductController', ProductController);

function ProductController($rootScope, $scope, $http) {

    $scope.deleteProduct = function (id) {
        if (confirm('Biztosan törlöd ezt a terméket?')) {
            var url = productDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade',250,function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    }

    $scope.removeImage = function (id, picNumber, hasPic) {
        if (hasPic && confirm('Biztosan törlöd ezt a képet?')) {
            var url = productImageDeleteURL + '/' + id+'/'+picNumber;
            $("#loader"+picNumber).addClass("active");
            $http.delete(url)
                .then(function successCallback(response) {
                    $("#loader"+picNumber).removeClass("active");
                    $('#img'+picNumber).val(null);
                    $('#disp'+picNumber).attr('src' ,'/img/noimg.png');
                }, function errorCallback(response) {
                    $("#loader"+picNumber).removeClass("active");
                    alert("Hiba történt törlés közben.");
                });
        }
    }
}
