angular.module('app').controller('marketPlaceController', marketPlaceController);

function marketPlaceController($scope, ShoppingCartService, ArticleService) {

    ArticleService.loadDeliveries();

    ShoppingCartService.loadShoppingCart();

}




