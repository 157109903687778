
angular.module('app').service('ShoppingCartService', ShoppingCartService);

function ShoppingCartService($q, HttpService, ArticleService, ErrorHandlingService, TimeoutService, Flash) {

    var shoppingCartService = this;

    shoppingCartService.shoppingCart = {};
    shoppingCartService.shoppingCart.shoppingCartItems = [];
    shoppingCartService.shoppingCartOrdered = {};
    shoppingCartService.shoppingCartOrdered.shoppingCartItems = [];
    shoppingCartService.shoppingCart.value = 0;
    shoppingCartService.shoppingCartOrdered.value = 0;
    shoppingCartService.user = {};
    shoppingCartService.shoppingCartDeliveries = [];

    shoppingCartService.shoppingCartChanged = null;

    shoppingCartService.getShoppingCart = function () {
        return shoppingCartService.shoppingCart;
    }

    shoppingCartService.hasElement = function () {
        return shoppingCartService.shoppingCart.shoppingCartItems.length!==0;
    }

    shoppingCartService.loadShoppingCart = function () {
        return $q(function(resolve, reject) {
            HttpService.get('/api/shoppingCart', {}).then(
                function (response) {
                    response.data.shoppingCartDeliveries.forEach(shoppingCartDelivery => {
                        shoppingCartDelivery.delivery.jsDate = new Date(shoppingCartDelivery.delivery.date);
                    });

                    _.assign(shoppingCartService.shoppingCart, HttpService.toCamelCaseProperties(response.data.shoppingCart));
                    _.assign(shoppingCartService.user, HttpService.toCamelCaseProperties(response.data.user));
                    _.assign(shoppingCartService.shoppingCartOrdered, HttpService.toCamelCaseProperties(response.data.shoppingCartOrdered));
                    _.assign(shoppingCartService.shoppingCartDeliveries, HttpService.toCamelCaseProperties(response.data.shoppingCartDeliveries));

                    shoppingCartService.shoppingCart.value = 0;
                    _.forEach(shoppingCartService.shoppingCart.shoppingCartItems, function (shoppingCartItem) {
                        shoppingCartService.shoppingCart.value += shoppingCartItem.price;
                    });

                    shoppingCartService.shoppingCartOrdered.value = 0;
                    _.forEach(shoppingCartService.shoppingCartOrdered.shoppingCartItems, function (shoppingCartItem) {
                        shoppingCartService.shoppingCartOrdered.value += shoppingCartItem.price;
                    });

                    TimeoutService.shoppingCartChanged(shoppingCartService.hasElement());

                    resolve();
                }, function (error) {
                    reject(error.data);
                    ErrorHandlingService.handleException(error.data);
                });
        });
    }

    shoppingCartService.addToCart = function (article, amount) {
        return $q(function(resolve, reject) {
            HttpService.post('/api/addToShoppingCart', {'article_id': article.id, 'amount': amount, 'delivery_id': ArticleService.filter.delivery.id}).then(
                function (response) {
                    shoppingCartService.loadShoppingCart().then(function () {
                        article.free -= amount;
                        resolve();
                    });
                }, function (error) {
                    if (error.data && error.data.errors && error.data.errors.not_enough_articles) {
                        article.free = 0;
                        Flash.create('danger', 'Sajnos a termék menet közben elfogyott!');
                    }

                    reject(error.data);
                    ErrorHandlingService.handleException(error.data.errors);
                });
        });
    }

    shoppingCartService.removeFromCart = function (shoppingCartItem) {
        return $q(function(resolve, reject) {
            HttpService.post('/api/removeFromShoppingCart', {
                'shopping_cart_item_id': shoppingCartItem.id
            }).then(
                function (response) {
                    shoppingCartService.loadShoppingCart().then(function () {
                        var article = ArticleService.getArticle(shoppingCartItem.articleId);
                        article.free = article.free + shoppingCartItem.amount;
                        resolve();
                    });
                }, function (error) {
                    reject(error.data);
                    ErrorHandlingService.handleException(error.data);
                });
        });
    }

    shoppingCartService.order = function () {
        return $q(function(resolve, reject) {
            HttpService.post('/api/orderShoppingCart', {
            }).then(
                function (response) {
                    shoppingCartService.loadShoppingCart().then(function () {
                        resolve();
                    });
                }, function (error) {
                    reject(error.data);
                    ErrorHandlingService.handleException(error.data);
                });
        });
    }

    shoppingCartService.updateDelivery = function () {
        return $q(function(resolve, reject) {
            HttpService.post('/api/updateShoppingCartDelivery', {
                shoppingCartDeliveries: HttpService.toSnakeCaseProperties(shoppingCartService.shoppingCartDeliveries)
            }).then(
                function (response) {
                    shoppingCartService.loadShoppingCart().then(function () {
                        resolve();
                    });
                }, function (error) {
                    reject(error.data);
                    ErrorHandlingService.handleException(error.data);
                });
        });
    }

}