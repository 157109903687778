angular.module('app').controller('searchResultsController', searchResultsController);

function searchResultsController($rootScope, $scope, $timeout, $http, HttpService, ArticleService, ShoppingCartService) {

    var IMG_NOIMG = '/img/noimg.png';
    var IMG_EXTENSION_LARGE = '_L';
    var IMG_EXTENSION_SMALL = '_S';

    $scope.articles = ArticleService.articles;
    $scope.filter = ArticleService.filter;

    $scope.shoppingCartDeliveries = ShoppingCartService.shoppingCartDeliveries;

    $scope.articleSelected = null;
    $scope.articleSelectedImage = null;
    $scope.articleSelectedImageLarge = null;
    $scope.articleDownloading = false;

    this.refreshSticky = function () {
        $timeout(function () {
            $('.ui.sticky')
                .sticky('refresh');
        });
    }

    this.showArticleDetails = function (article) {
        $scope.articleDownloading = false;

        $scope.articleSelected = article;
        this.selectImage(article.images[0] ? article.images[0] : IMG_NOIMG);

        $('#articleDetails')
            .modal('show');
    }

    this.downloadArticleDetails = function (article) {
        this.showArticleDetails(article);

        $scope.articleDownloading = true;
    }

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link.click();
    }

    this.downloadAsImage = function () {
        if (!$scope.articleDownloading) {
            return;
        }

        html2canvas(document.querySelector("#articleDetails")).then(canvas => {
            var myImage = canvas.toDataURL("image/png");
            downloadURI("data:" + myImage, "article.png");
        });
    }

    this.increaseOrderUnits = function (article) {
        if ((article.orderUnits + 1) * article.minimum_order_quantity <= article.free) {
            article.orderUnits += 1;
        }
    }

    this.decreaseOrderUnits = function (article) {
        if ((article.orderUnits - 1) >= 0) {
            article.orderUnits -= 1;
        }
    }

    this.orderUnitsChanged = function (articleSelected) {
        if (!articleSelected.orderUnits) {
            articleSelected.orderUnits = 1;
        }
    }

    this.addToCart = function (article, amount) {
        if (!article.loading) {
            article.loading = true;
            $('#articleDetails')
                .modal('hide');
            ShoppingCartService.addToCart(article, amount).then(
                function () {
                    article.loading = false;

                    if ($scope.shoppingCartDeliveries.some(shoppingCartDelivery => !shoppingCartDelivery.isConfirmed)) {
                        $('#shoppingCartDeliveryAndPaymentInfo')
                            .modal('show');
                    }
                }, function (error) {
                    if (error.closed_delivery) {
                        $rootScope.errorMessage = "Sajnáljuk, menet közben a kiszállítási dátum lezárásra került.";
                        $('#errorPopup')
                            .modal('show');
                    }
                    if (error.not_enough_articles) {
                        $rootScope.errorMessage = "Sajnáljuk, menet közben a kiválasztott termék elfogyott.";
                        $('#errorPopup')
                            .modal('show');
                    }
                    article.loading = false;
                }
            );
        }
    }

    this.getLargeImage = function (smallImage) {
        return smallImage.replace(IMG_EXTENSION_SMALL, IMG_EXTENSION_LARGE);
    }

    this.selectImage = function(image) {
        $scope.articleSelectedImage = image;
        $scope.articleSelectedImageLarge = this.getLargeImage(image);
    }
}
