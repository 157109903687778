angular.module('app').controller('DeliveryController', DeliveryController);

function DeliveryController($rootScope, $scope, $http) {
    $scope.deleteDelivery = function (id) {
        if (confirm('Biztosan törlöd ezt a szállítási időpontot?')) {
            var url = deliveryDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade',250,function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    }

    $scope.sendNewsletter = function (id, admin_only) {
        $("#closeLoader").addClass("active");
        var url = prepareNewsletterURL + '/' + id + '/' + (admin_only ? '1' : '0');
        $http.get(url)
            .then(function successCallback(response) {
                $("#closeLoader").removeClass("active");
                $('#app').content = response;
            }, function errorCallback(response) {
                alert("Hiba történt küldés közben.");
                $("#closeLoader").removeClass("active");
            });
    }
}
