
angular.module('app').service('ErrorHandlingService', ErrorHandlingService);

function ErrorHandlingService(Flash) {

    var errorHandlingService = this;

    var errorCodesToIgnore = ['not_enough_articles', 'closed_delivery'];

    errorHandlingService.handleException = function (errors) {
        if (typeof errors === 'string' || errors instanceof String) {
            Flash.create('danger', errors);
            console.log(errors);
        } else {
            angular.forEach(errors, function (error, key) {
                if (!errorCodesToIgnore.find(function (errorCode) {
                        return (errorCode == key);
                    })) {
                    Flash.create('danger', error);
                    console.log(error);
                }
            });
        }
    };
}

