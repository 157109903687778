angular.module('app').controller('UnitController', UnitController);

function UnitController($rootScope, $scope, $http) {
    $scope.deleteUnit = function (id) {
        if (confirm('Biztosan törlöd ezt a mennyiségi egységet?')) {
            var url = unitDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade',250,function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    }
}
