angular.module('app').controller('QualityController', QualityController);

function QualityController($rootScope, $scope, $http) {

    $scope.deleteQuality = function (id) {
        if (confirm('Biztosan törlöd ezt a minőséget?')) {
            var url = qualityDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade',250,function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    }
}
