angular.module('app').controller('ImportController', ImportController);

function ImportController(HttpService, $rootScope, $scope, $http, Upload, $timeout) {

    $scope.fileselected = function (file, invalidFiles) {
        if (file) {
            $("#filename").text(file.name);
            $("#check").removeClass("disabled");
            $("#import").addClass("disabled");
            $("#claim").addClass("disabled");
            $scope.fileHash = null;
        }
    }

    $scope.checkImport = function (file, deliveryId, importType) {
        $("#import").addClass("disabled");
        $("#claim").addClass("disabled");
        $("#loader").addClass("active");
        $scope.hasResults = true;

        file.upload = Upload.upload({
            method: 'POST',
            url: checkImportFileUrl,
            data: {file: file, deliveryId: deliveryId, importType: importType},
        });

        file.upload.then(
            function (response) {
                $("#loader").removeClass("active");
                $("#export").removeClass("disabled");
                $scope.export_url = generate_export_url(response.data.filehash, deliveryId, importType);
                response.data.criticals = response.data.criticals || [];
                processResponse(response.data);
            },
            function (response) {
                $('#result').html(response.data);
                $("#loader").removeClass("active");
                if (response.status > 0) {
                    $scope.errorMsg = response.status + ': ' + response.data;
                }
            }
        );
    }

    $scope.import = function (deliveryId, importType) {

        $("#loader").addClass("active");
        $('#result_container').show();

        $http.post(importFileUrl,{filehash: $scope.fileHash, deliveryId: deliveryId, importType: importType}).then(
            function (response) {
                $("#loader").removeClass("active");
                processResponse(response.data);
                $("#check").addClass("disabled");
                $("#import").addClass("disabled");
                $("#claim").removeClass("disabled");
                $scope.claim_url = $scope.claim(deliveryId, importType);
            },
            function (response) {
                $('#result').html(response.data);
                $("#loader").removeClass("active");
                if (response.status > 0)
                    $scope.errorMsg = response.status + ': ' + response.data;
            }
        );
    }

    $scope.claim = function (deliveryId, importType) {
        return claimImportFileUrl + '?filehash=' + $scope.fileHash + '&deliveryId=' + deliveryId + '&importType=' + importType;
    }

    function generate_export_url(filehash, deliveryId, importType) {
        return exportImportFileUrl + '?filehash=' + filehash + '&deliveryId=' + deliveryId + '&importType=' + importType;
    }

    function processResponse(data) {
        $scope.criticals = data.criticals;
        $scope.missings = data.missings;
        $scope.differences = data.differences;

        if ($scope.criticals.length==0) {
            $scope.fileHash = data.filehash;
            $("#import").removeClass("disabled");
        }
    }
}
