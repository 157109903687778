
angular.module('app').service('HttpService', HttpService);

function HttpService($http) {

    var httpService = this;

    httpService.get = function (url, params) {
        return $http.get(url, {params: params});
    }

    httpService.post = function (url, data) {
        return $http.post(url, data);
    }

    _.mixin({
        deeply: function (map) {
            return function(obj, fn) {
                return map(_.mapValues(obj, function (v) {
                    if (_.isPlainObject(v)) {
                        return _.deeply(map)(v, fn);
                    } else if (_.isArray(v)) {
                        var array = [];
                        _.forEach(v, function (value) {
                            array.push(_.deeply(map)(value, fn));
                        });
                        return array;
                    } else {
                        return v;
                    }
                }), fn);
            }
        },
    });

    httpService.toCamelCaseProperties = function (obj) {
        return _.deeply(_.mapKeys)(obj, function (val, key) {
            return _.camelCase(key);
        });
    }

    httpService.toSnakeCaseProperties = function (obj) {
        return _.deeply(_.mapKeys)(obj, function (val, key) {
            return _.snakeCase(key);
        });
    }
}